import React from "react"
import Layout from "../components/Layout"
import * as notFoundStyles from "../styles/404.module.css"

export default function NotFound() {
	return (
		<Layout>
			<header className={notFoundStyles.notFoundWrapper}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h1>404</h1>
							<p>Sorry, that page doesn't exist.</p>
						</div>
					</div>
				</div>
			</header>
		</Layout>
	)
}